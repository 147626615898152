import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
	DocsTable,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { BaseTable, BaseTableCellPosition } from "./base-table";

export const Meta: DocPageMeta = {
	category: "Tables and lists",
	title: "Base table",
	notepad: "https://hackmd.io/p_92Tq71SFe1vAd1WhDv2A",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Base table"
			subTitle="A simplified table component for displaying structured data in a clean, accessible format."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={BaseTable} />
				<Header.H2>Table vs. Base Table</Header.H2>
				<DocsTable>
					<thead>
						<tr>
							<th>Component</th>
							<th>Purpose</th>
							<th>Usage</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink>
							</td>
							<td>
								A feature-rich table component optimized for complex data interactions. Offers
								sorting, filtering, pagination, row selection, etc.
							</td>
							<td>Displaying extensive datasets requiring user manipulation.</td>
						</tr>
						<tr>
							<td>
								<GatsbyLink to="/lab/components/Tables and lists/Base table">Base table</GatsbyLink>
							</td>
							<td>
								A streamlined table for presenting structured data without advanced interactions.
							</td>
							<td>
								Displaying smaller datasets within dashboards or cards where complex interactions
								are not the primary focus.
							</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H2>Examples</Header.H2>
				<Header.H3>Basic usage</Header.H3>
				<Paragraph>This will render a basic table with headers and data cells.</Paragraph>
				<Example fn={BasicUsage} />
				<Header.H3>With caption</Header.H3>
				<Paragraph>
					The caption provides a brief description of the table's content, aiding screen reader
					users and improving overall comprehension.
				</Paragraph>
				<Example fn={CaptionUsage} />
				<Header.H3>Expandable cells</Header.H3>
				<Paragraph>
					Enable users to delve deeper into specific data points by making cells expandable. This
					allows for the display of additional details or actions within the cell.
				</Paragraph>
				<Paragraph>
					Columns can be marked expandable.<Code>isExpandable</Code> is a function that gets called
					for each cell so you can control exactly which ones are expandable.
				</Paragraph>
				<Example fn={ExpandableUsage} />
				<Header.H3>With row highlight</Header.H3>
				<Paragraph>Visually emphasize specific rows based on certain criteria.</Paragraph>
				<Paragraph>
					You can do so by providing a function as <Code>highlightRow</Code> prop which takes an
					item as parameter and returns a boolean which indicates whether or not to highlight an
					item. This function gets called for each row and adds a highlight class to those matching
					the criteria.
				</Paragraph>
				<Example fn={HighlightedRowUsage} />
				<Header.H3>With column summary</Header.H3>
				<Paragraph>
					You can also display an extra row in the table header that contains the summary of a
					corresponding column. The summary content is formed by a number value and a label. The
					number value can also be provided as a string, enabling the use of number formatters. To
					display the summary of a column, use the property <Code>summary</Code> in the
					specifications of a column, as exemplified below.
				</Paragraph>
				<Example fn={SummaryUsage} />
				<Header.H3>Keeping state</Header.H3>
				<Paragraph>
					You might've noticed in the <em>Expandable cells</em> example that nothing happens when
					clicking the expander. Why? Because <Code>BaseTable</Code> is stateless. It's not its
					concern to know what's expanded, only to render that it <em>is</em> expandable. Lets hook
					it up so expanding works. In the below example note how we keep track of state ourselves,
					and push that state into BaseTable so it always renders the right thing.
				</Paragraph>
				<Example fn={StatefulUsage} />
				<Header.H3>Loading state</Header.H3>
				<Paragraph>
					When fetching data, provide a visual indicator (e.g. Spinner) to inform the user that the
					table is loading.
				</Paragraph>
				<Example fn={LoadingStateUsage} />
				<Header.H3>No data state</Header.H3>
				<Paragraph>
					When no data is available, display a clear, informative message ( e.g. "Accessibility
					issues have been resolved."). Consider the context and provide guidance on how to resolve
					the issue if possible.
				</Paragraph>
				<Paragraph>
					The <Code>noDataState</Code> is used to indicate that no data is available for display. An{" "}
					<GatsbyLink to="/lab/components/Visuals/Empty State">Empty State</GatsbyLink> component
					with the <Code>type</Code> "reassure" and default heading is shown, but it can be
					overridden by another type with custom text. For guidelines please refer to the{" "}
					<GatsbyLink to="/lab/components/Visuals/Empty State">Empty State</GatsbyLink> component.
				</Paragraph>
				<Example fn={NoDataStateUsage} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={BaseTable}
					initialProps={{
						items: [
							{ one: "foo", two: "bar" },
							{ one: "bar", two: "foo" },
						],
						columns: [
							{
								header: "Header 1",
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								render: (item) => <div>{(item as any).one}</div>,
								isKeyColumn: true,
							},
							{
								header: "Header 2",
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								render: (item) => <div>{(item as any).two}</div>,
							},
						],
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>BaseTable</Code> when
					</Paragraph>
					<Ul
						items={[
							"Presenting tabular data where visual clarity and comparison are prioritise.",
							"Displaying smaller to medium-sized datasets.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>BaseTable</Code> are typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								Within <GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>: Provides
								detailed information within a Card component's content area.
							</>,
							<>On Dashboards: Summarizes key data points within a Dashboard widgets.</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>BaseTable</Code> to existing components for visual
								consistency.
							</>,

							<>
								<b>Clarity and readability</b>:
								<Ul
									items={[
										<>
											<b>Column Formatting</b>: Ensure consistency within each column using
											specialized components (<Code>FormattedDate</Code>,{" "}
											<Code>FormattedNumber</Code>, <Code>BigSmall</Code>, <Code>TitleUrl</Code>)
											for dates, numbers, and URLs.
										</>,
										<>
											<b>Column Headers</b>: Keep them concise, descriptive, and relevant to the
											data.
										</>,
										<>
											<b>Visual Hierarchy</b>: Employ visual cues (bold text, color) to emphasize
											important data points within the table.
										</>,
									]}
								/>
							</>,
							<>
								<b>Responsiveness</b>: Design for optimal viewing on various screen sizes, ensuring
								the table adapts gracefully to different viewports.
							</>,
							<>
								<b>Error/ Empty State</b>: In case of errors fetching or no data displayed, present
								a user-friendly error message and offer suggestions for troubleshooting or retrying.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								Displaying large datasets. Use{" "}
								<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink> instead.
							</>,
							<>
								Showing simple lists or unstructured data. Use{" "}
								<GatsbyLink to="/lib/components/Text/List">List</GatsbyLink> or{" "}
								<GatsbyLink to="/lab/components/Tables and lists/List table ">
									List Table{" "}
								</GatsbyLink>
								instead
							</>,
							<>
								Data is better visualized in a different format. Use{" "}
								<GatsbyLink to="/lab/components/Data Visualization/Chart">Chart</GatsbyLink>{" "}
								instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul items={[<>Ensure sufficient color contrast between text and background.</>]} />
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						For tables that have more than one column, consider giving it a key column. The key
						column is useful for screen reader users, as it will describe the columns to its right
						side. A table should not have more than one key column, hence only the first column that
						is marked as key column will be used. The key column must not be the last column to the
						right. You can mark the column that contains the main information as a key column by
						setting the optional <Code>isKeyColumn</Code> prop in the column's config to{" "}
						<Code>true</Code>. This will add <Code>scope="row"</Code> to the key column's{" "}
						<Code>{`<th>`}</Code> tag, helping screen reader users better understand the table's
						content.
					</Paragraph>
					<Paragraph>
						If the table should not have a key column, you can set the optional{" "}
						<Code>withoutKeyColumn</Code> prop of <Code>BaseTable</Code> to <Code>true</Code>, which
						will silence the console error the component prints when none of a table's column
						configs are marked as a key column.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Keep column headers concise, descriptive, and relevant to the data.",
							"Avoid excessive abbreviations or jargon.",
							<>
								Use sentence case of each header unless proper nouns and Siteimprove products and
								features are present. Read{" "}
								<a href="https://fancy.siteimprove.com/Writing/grammar-and-mechanics#capitalization">
									Capitalization rule
								</a>
								.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<BaseTable
		items={[{ text: "foo" }, { text: "bar" }]}
		columns={[{ header: "Header", render: (item) => <div>{item.text}</div> }]}
		withoutKeyColumn
	/>
);

const CaptionUsage = () => (
	<BaseTable
		items={[{ text: "foo" }, { text: "bar" }]}
		columns={[{ header: "Header", render: (item) => <div>{item.text}</div> }]}
		caption="Caption for table"
		withoutKeyColumn
	/>
);

const ExpandableUsage = () => (
	<BaseTable
		items={[
			{ text: "foo", count: 0 },
			{ text: "bar", count: 1 },
		]}
		columns={[
			{
				header: "Header",
				render: (item) => <div>{item.text}</div>,
				isExpandable: (item) => item.count > 0,
			},
		]}
		withoutKeyColumn
	/>
);

const HighlightedRowUsage = () => (
	<BaseTable
		items={[
			{ text: "foo", count: 0 },
			{ text: "bar", count: 1 },
		]}
		columns={[
			{ header: "Header", render: (item) => <div>{item.text}</div>, isKeyColumn: true },
			{ header: "Count", render: (item) => <div>{item.count}</div> },
		]}
		highlightRow={(item) => item.text === "foo"}
	/>
);

const SummaryUsage = () => (
	<BaseTable
		items={[
			{ text: "foo", count: 0 },
			{ text: "bar", count: 1 },
		]}
		columns={[
			{ header: "Header", render: (item) => <div>{item.text}</div>, isKeyColumn: true },
			{
				header: "Count",
				render: (item) => <div>{item.count}</div>,
				summary: {
					value: 1,
					label: "total",
				},
			},
		]}
	/>
);

const StatefulUsage = () => {
	const [state, setState] = useState<BaseTableCellPosition>();
	return (
		<BaseTable
			items={[{ text: "foo" }, { text: "bar" }]}
			columns={[
				{
					header: "Header",
					render: (item) => <div>{item.text}</div>,
					isExpandable: () => true,
				},
			]}
			withoutKeyColumn
			expandedContentRenderer={() => <div>I 💓 expanded content</div>}
			expandedCellPosition={state}
			onExpandClick={(e, cellPosition) => {
				// If the user clicks an already-expanded cell we want to collapse, i.e. reset state
				if (
					state &&
					state.columnNum == cellPosition.columnNum &&
					state.rowNum == cellPosition.rowNum
				) {
					setState(undefined);
					return;
				}
				setState(cellPosition);
			}}
		/>
	);
};

const LoadingStateUsage = () => (
	<BaseTable<{ text: string; count: number }>
		loading={true}
		loadingText="Loading items"
		items={[]}
		columns={[
			{ header: "Header", render: (item) => <div>{item.text}</div>, isKeyColumn: true },
			{ header: "Count", render: (item) => <div>{item.count}</div> },
		]}
	/>
);

const NoDataStateUsage = () => (
	<BaseTable<{ text: string; count: number }>
		items={[]}
		columns={[
			{ header: "Header", render: (item) => <div>{item.text}</div>, isKeyColumn: true },
			{ header: "Count", render: (item) => <div>{item.count}</div> },
		]}
	/>
);
